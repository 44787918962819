<template>
  <div class="home">
    <!-- <van-pull-refresh
      style="height: 100vh"
      v-model="isLoading"
      @refresh="onRefresh"
    > -->
      <div style="font-size: 14px; margin: 10px 0" @click="out">返回登录</div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../../assets/mobileImg/banner/01.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/mobileImg/banner/02.png" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/mobileImg/banner/03.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <div style="height: 35px"></div>
      <div class="flexLR">
        <span class="decoration">请选择企业</span>
        <span
          style="color: #1a6ce8"
          @click="$router.push('/project/applyCompany')"
          >申请加入企业</span
        >
      </div>
      <van-dropdown-menu
        active-color="#1989fa"
        style="margin: 15px 0 20px; padding: 0 10px"
      >
        <van-dropdown-item
          v-model="atCompany"
          @change="cutCompany"
          :options="companyTree"
        />
      </van-dropdown-menu>
      <div class="flexLR">
        <span class="decoration">请选择要进入的项目</span>
        <span
          style="color: #1a6ce8"
          @click="$router.push('/project/applyProject')"
          >申请加入项目</span
        >
      </div>

      <div style="margin: 20px 0; overflow: auto; height: 45%">
        <van-list
          style="min-height: 100%"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div class="myDivs" v-for="(item, index) in projectList" :key="index">
            <div class="item flexLR">
              <div class="itemL">
                <div
                  :class="{ admin: item.prou07 == 1, pt: item.prou07 != 1 }"
                  class="img"
                  :style="`background-image:url(${
                    $store.state.baseUrl + item.proj24
                  })`"
                ></div>
              </div>
              <div
                class="flexcolumn"
                style="width:55%;height:100%;space-between;align-items:start"
              >
                <div>
                  项目名称：<span>{{ item.proj07 }}</span>
                </div>
                <div>
                  项目经理：<span>{{ item.admin }}</span>
                </div>
                <div>
                  项目类型：<span>{{ item.proj10 }}</span>
                </div>
              </div>
              <div class="itemR" @click="projectHome(item)">→</div>
            </div>
          </div>
        </van-list>
      </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import { GetUserCompanyOpen, GetMyIndex } from "@/api";
export default {
  data() {
    return {
      companyTree: [],
      atCompany: 1,
      isLoading: false,
      projectList: [],
      loading: false,
      finished: false,
      pageSize: 0,
    };
  },
  created() {
    this.getCompany();
  },
  computed: {},
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },
      // slidesPerView: 1.36,
      // centeredSlides: true,
      spaceBetween: 20,
      autoplay: {
        delay: 2000, //时间 毫秒
        disableOnInteraction: false, //用户操作之后是否停止自动轮播默认true
      },
    });
  },
  methods: {
    cutCompany(v) {
      this.atCompany = v;
      this.$store.state.qid = v;
      this.onLoad();
    },
    async getCompany() {
      this.loading = true;
      let res = await GetUserCompanyOpen({
        params: {
          id: this.$store.state.uid,
        },
      });
      this.companyTree = res.Data.map((v) => {
        return { text: v.Company, value: v.comu03 };
      });
      if (this.$store.state.qid != "") {
        this.atCompany = this.$store.state.qid;
      } else {
        this.$store.state.qid = res.Data[0].comu03;
        this.atCompany = res.Data[0].comu03;
      }
      this.onLoad();
      this.loading = false;
    },
    projectHome(item) {
      this.$router.push("home");
      this.$store.state.pid = item.proj01;
      this.$store.state.pImg = this.$store.state.baseUrl + item.proj24;
      this.$store.state.pName = item.proj07;
    },
    async close(action, done) {
      if (action == "cancel") {
        done();
      } else {
        let res;
      }
    },
    async onLoad() {
      this.loading = true;
      let res = await GetMyIndex({
        params: {
          pid: this.$store.state.qid,
          uid: this.$store.state.uid,
          type: 1,
          secahword: "",
          manager: 2,
        },
      });
      this.projectList = res.Data;
      this.loading = false;
      this.finished = true;
      this.isLoading = false;
    },
    onRefresh() {
      this.getCompany();
    },
    out() {
      this.$store.state.token = "";
      this.$store.state.uid = "";
      this.$store.state.pid = "";
      this.$store.state.qid = "";
      localStorage.setItem("isLogin", false);
      this.$router.push("/login");
    },
  },
};
</script>



<style  lang="scss">
/deep/ .van-cell__title {
  width: 80%;
}

.van-dropdown-menu__item {
  // justify-content: flex-start;
}
/deep/.van-dropdown-menu__bar {
  border-radius: 8px;
}
.home {
  padding: 10px 30px;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}
.swiper-container {
  border-radius: 25px;
  height: 280px;
  width: 100%;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #006eff;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.myDivs {
  width: 100%;
  height: 230px;
  margin-bottom: 35px;
  .item {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 6px 5px #f5f5f5;
    margin-top: 20px;
    border-radius: 20px;
    padding: 20px;
    width: 88.5%;
    height: 210px;
    margin-right: 20px;
    float: right;
    .itemL {
      left: -80px;
      top: -85px;
      width: 20%;
      height: 50%;
      position: relative;
      .pt,
      .admin {
        &::after {
          content: "协作";
          color: white;
          text-align: center;
          left: 0;
          top: 0;
          position: absolute;
          width: 80px;
          height: 35px;
          background: linear-gradient(
            to right,
            rgb(255, 145, 0),
            rgb(255, 217, 0)
          );
          border-radius: 10px 0 10px 0;
        }
      }
      .admin {
        &::after {
          content: "管理";
          background: linear-gradient(
            to right,
            rgb(0, 102, 255),
            rgb(0, 174, 255)
          );
        }
      }
      .img {
        background-image: url("../../assets/mobileImg/Rectangle 194.png");
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 210px;
        height: 180px;
        position: relative;
      }
    }
    .itemR {
      width: 10%;
      height: 60px;
      color: white;
      font-size: 36px;
      border-radius: 10px;
      text-align: center;
      position: relative;
      left: 38px;
      // line-height: 60px;
      background: linear-gradient(to right, rgb(0, 102, 255), rgb(0, 174, 255));
    }
    span {
      color: #1a6ce8;
    }
  }
}

.home .swiper-pagination {
  width: 100%;
  margin: 0 auto;
  span {
    margin: 0 5px;
  }
}
.home .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.home .swiper-pagination-bullet-active {
  background-color: #1a6ce8;
  width: 30px;
  border-radius: 30px;
  box-shadow: 0 4px 6px 1px rgba(0, 89, 255, 0.411);
}
</style>